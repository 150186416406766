.dates-container {
    align-items: center;
    display: flex;
    justify-content: flex-end;
}

.dates-container .react-datepicker-wrapper {
    width: auto;
}

.dates-container .react-datepicker__input-container input {
    border: 1px solid var(--bs-border-color-translucent);
    border-radius: 3px;
}

.calendar-icon {
    height: 30px;
    width: 30px;
}

