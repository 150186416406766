.dashboard-grid-container {
    font-size: 13px;
}

.dashboard-grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 1rem;
    grid-auto-rows: minmax(50px, auto);
}
